/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-error-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-message.component.html',
})
export class ErrorMessageComponent {
  @Input() errors: any;
  @Input() submitted = false;
  @Input() fieldName: string | undefined;
  @Input() minLength: string | undefined;
  @Input() maxLength: string | undefined;
  @Input() control!: AbstractControl;

  /**
   * Retrieves the appropriate error message based on the error type.
   * @param error The error type.
   * @returns The corresponding error message.
   */
  getErrorMessage(error: string): string {
    switch (error) {
      case 'required':
        return `${this.fieldName} is required.`;
      case 'minlength':
        return `${this.fieldName} must be at least ${this.minLength} characters long.`;
      case 'maxlength':
        return `${this.fieldName} must not exceed ${this.maxLength} characters.`;
      case 'min':
        return `${this.fieldName} must be at least 1.`;
      case 'startsWithTwo':
        return `${this.fieldName} is invalid. Please enter start with 2 or 3.`;
      case 'pattern':
        return `${this.fieldName} is invalid. Please enter a valid format.`;
      case 'email':
        return `Please enter a valid email format.`;
      case 'invalidFormat':
        return `${this.fieldName} a invalid format.`;
      case 'futureDate':
        return `${this.fieldName} should be past date.`;
      case 'invalidZipCode':
        return `Please enter a valid ZIP code`;
      case 'invalidSSNFormat':
        return `Invalid SSN Format`;
      case 'ownershipDateInvalid':
        return `Date of Ownership cannot be greater than Date of Birth.`;
      case 'ageInvalid':
        return `Age must be at least 18 years old.`;
      case 'maxTicketSizeInvalid':
        return 'Maximum Ticket Size cannot be less than Average Ticket Size.';
      case 'monthlyVolumeInvalid':
        return `Monthly Processing Volume must be larger than both Average Ticket Size and Maximum Ticket Size.`;
      default:
        return 'An error occurred.';
    }
  }

  /**
   * Finds the keys of an object.
   * @param obj The object.
   * @returns An array of keys.
   */
  findKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
